import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"

import Img from "../../utils/OptimizedImage"

const ConfidenceLogoWrapper = ({ component }) => (
  <Row className="mx-0 logos-wrapper">
    {useMemo(
      () =>
        component?.company &&
        component?.company.map(
          (company, index) =>
            company?.logo && (
              <Col
                xs={4}
                md={2}
                lg={2}
                key={index}
                className={`confidenceitem d-flex align-items-center justify-content-center img-block`}
              >
                {company?.logo?.gatsbyImageData ? (
                  <Img
                    key={index}
                    image={company?.logo?.gatsbyImageData}
                    alt={company?.name}
                  />
                ) : (
                  company?.logo?.url && (
                    <figure className="img-wrapper">
                      <Img
                        key={index}
                        src={company?.logo?.url}
                        alt={`Logo of ${company?.name}`}
                        height={68}
                        width="100%"
                      />
                    </figure>
                  )
                )}
              </Col>
            )
        ),
      [component?.company]
    )}
  </Row>
)

export default ConfidenceLogoWrapper
