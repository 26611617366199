import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import Marquee from "react-fast-marquee"

import Img from "../../utils/OptimizedImage"

const ConfidenceMarquee = ({ component }) => (
  <Row>
    <Col lg={12}>
      <div className="confidence-scroll-wrapper">
        <Marquee pauseOnHover speed={20}>
          {useMemo(
            () =>
              component?.company &&
              component.company.map((company, index) =>
                company?.logo?.gatsbyImageData ? (
                  <Img
                    key={index}
                    image={company?.logo?.gatsbyImageData}
                    alt={company?.name}
                  />
                ) : (
                  company?.logo?.url && (
                    <Img
                      key={index}
                      src={company?.logo?.url}
                      alt={company?.name}
                      height={68}
                      width="100%"
                    />
                  )
                )
              ),
            [component?.company]
          )}
        </Marquee>
      </div>
    </Col>
  </Row>
)
export default ConfidenceMarquee
