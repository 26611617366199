import { render } from "datocms-structured-text-to-plain-text"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"

import { ConfidenceBarContainer } from "../../styles/Components/ConfidenceBar/index"
import Heading from "../../utils/OptimizedHeading"
import useInViewPort from "../../utils/useInViewPort"

import ConfidenceLogoWrapper from "./LogosWrapper"
import ConfidenceMarquee from "./Marquee"
import ConfidenceSwiper from "./Swiper"

const ConfidenceBar = ({ component, grid }) => {
  const bgClass = component?.background?.replace(/ /g, "").toLowerCase()
  const [nodeRef, isVisible] = useInViewPort()

  const designVersion = component?.newDesign === true ? "new" : ""
  const withHeadingElement =
    component?.eyebrow || component?.heading || Boolean(render(component?.body))
  const gridIconSize = component?.iconSize ? component?.iconSize : "standard"
  const negativeMarginBottom = component?.negativeMarginBottom

  return (
    <ConfidenceBarContainer
      className={`${designVersion} bg-${bgClass} container-${
        grid !== "" ? grid?.toLowerCase() : "normal"
      }`}
      noPaddingTop={component?.noPaddingTop}
      noPaddingBottom={component?.noPaddingBottom}
      doublePaddingBottom={component?.doublePaddingBottom}
      gridIconSize={gridIconSize}
      negativeMarginBottom={negativeMarginBottom}
    >
      <Container ref={nodeRef}>
        <Row>
          <Col>
            {withHeadingElement && (
              <div className="heading-container">
                {component?.eyebrow && (
                  <p className="heading-kicker eyebrow">{component?.eyebrow}</p>
                )}
                {component?.heading && (
                  <Heading
                    type={component?.headingSize}
                    string={component?.heading}
                    className="heading"
                  />
                )}
                {Boolean(render(component?.body)) && (
                  <div className="description">
                    <StructuredText data={component?.body} />
                  </div>
                )}
              </div>
            )}
            {isVisible && (
              <div
                className={`main-container ${
                  withHeadingElement ? "with-heading" : ""
                }`}
              >
                {component?.variant &&
                component?.variant.toLowerCase() === "grid" ? (
                  <ConfidenceLogoWrapper component={component} />
                ) : component?.variant &&
                  component?.variant.toLowerCase() === "carrousel" ? (
                  <ConfidenceSwiper component={component} />
                ) : (
                  <ConfidenceMarquee component={component} />
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </ConfidenceBarContainer>
  )
}

export default ConfidenceBar
