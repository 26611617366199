import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import shortid from "shortid"
import { Navigation, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper-bundle.css"
import Img from "../../utils/OptimizedImage"
import ViewCheck from "../../utils/screenResponsive"

const ConfidenceSwiper = ({ component }) => (
  <Row>
    <Col lg={12}>
      <Swiper
        slidesPerView={ViewCheck() ? 2 : 6}
        loop={true}
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {useMemo(
          () =>
            component?.company &&
            component?.company.length > 0 &&
            component?.company.map((company, index) => (
              <SwiperSlide key={index}>
                {company?.logo?.gatsbyImageData ? (
                  <Img
                    key={index}
                    image={company?.logo?.gatsbyImageData}
                    alt={company?.name}
                  />
                ) : (
                  company?.logo?.url && (
                    <Img
                      key={shortid.generate()}
                      src={company?.logo?.url}
                      alt={`Logo of ${company?.name}`}
                      height={68}
                      width="100%"
                    />
                  )
                )}
              </SwiperSlide>
            )),
          [component?.company]
        )}
      </Swiper>
    </Col>
  </Row>
)
export default ConfidenceSwiper
