import styled, { css } from "styled-components"

import { color } from "../../../atoms/colors"

const sizes = {
  standard: css`
    .confidenceitem {
      img {
        width: 100px;
        max-height: 72px;
      }
      .img-wrapper {
        @media (max-width: 767.98px) {
          padding-bottom: 40px;
        }
      }
    }
  `,
  large: css`
    .confidenceitem {
      .gatsby-image-wrapper {
        position: relative;
        width: 100%;
        max-height: 160px;
      }
      img {
        width: 100%;
        max-height: 150px;
        object-position: center;
        object-fit: scale-down !important;
        @media (max-width: 767.98px) {
          padding-bottom: 40px;
        }
      }
    }
  `,
}

export const ConfidenceBarContainer = styled.div`
  padding-top: ${(props) => (props.noPaddingTop ? "0px" : "96px")};
  padding-bottom: ${(props) =>
    props.noPaddingBottom
      ? "0px"
      : props?.doublePaddingBottom
      ? "192px"
      : "96px"};
  padding-left: 0;
  padding-right: 0;
  margin-bottom: ${(props) =>
    props?.negativeMarginBottom ? "-175px" : "unset"};
  @media (max-width: 1700px) {
    margin-bottom: ${(props) =>
      props?.negativeMarginBottom ? "-125px" : "unset"};
  }
  @media (max-width: 992px) {
    padding-top: ${(props) => (props.noPaddingTop ? "0px" : "56px")};
    padding-bottom: ${(props) =>
      props.noPaddingBottom
        ? "0px"
        : props?.doublePaddingBottom
        ? "112px"
        : "56px"};
    margin-bottom: ${(props) =>
      props?.negativeMarginBottom ? "-75px" : "unset"};
  }
  @media (max-width: 576px) {
    padding-top: ${(props) => (props.noPaddingTop ? "0px" : "40px")};
    padding-bottom: ${(props) =>
      props.noPaddingBottom
        ? "0px"
        : props?.doublePaddingBottom
        ? "80px"
        : "40px"};
    margin-bottom: ${(props) =>
      props?.negativeMarginBottom ? "-50px" : "unset"};
  }
  &.bg-sprite {
    .eyebrow {
      border: 2px solid ${color.oxfordBlue[600]} !important;
      color: ${color.oxfordBlue[600]} !important;
    }
    .description {
      color: ${color.oxfordBlue[900]} !important;
    }
    .heading {
      color: ${color.common.white} !important;
    }
    .overlay {
      &::before {
        background: linear-gradient(to right, #3dc4d2, rgba(255, 255, 255, 0));
      }
      &::after {
        background: linear-gradient(to right, #7ec658, rgba(255, 255, 255, 0));
      }
    }
  }
  &.bg-plum {
    .heading {
      color: ${color.common.white} !important;
    }
    .overlay {
      --gradient-color: ${color.oxfordBlue[900]}, rgba(255, 255, 255, 0) !important;
    }
  }
  &.bg-green-blue-gradient {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        ${color.common.white} 58.85%
      ),
      linear-gradient(
        250.72deg,
        #abe2f4 -8.68%,
        #f0f7e9 62.92%,
        #edfbff 117.43%
      );
  }
  .heading-container {
    text-align: center;
    max-width: 900px !important;
    margin: 0 auto;
    .eyebrow {
      font-size: 14px;
      font-weight: 500;
      border: 2px solid ${color.secondary[600]};
      border-radius: 40px;
      padding: 7px 15px 3px;
      line-height: 1em;
      display: inline-block;
      margin-bottom: 24px;
      color: ${color.secondary[600]};
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 18px;
      }
    }
    .heading {
      color: ${color.oxfordBlue[900]};
      margin-bottom: 20px;
      font-weight: 500;
      font-style: normal;
      font-size: 28px;
      line-height: 35px;
    }
    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
    }
  }
  .main-container {
    &.with-heading {
      margin-top: 30px;
    }

    /* scroll wrapper -> marquee */
    .confidence-scroll-wrapper {
      overflow: hidden;
      position: relative;
      .marquee-container {
        .overlay {
          @media (max-width: 576px) {
            display: none;
          }
        }
      }
      img {
        max-width: 100px;
        margin-right: 50px;
      }
      .marquee {
        min-width: unset;
      }
      .slick-track {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;
        .slick-slide {
          margin-right: 40px;
        }
      }
    }
    /* logos wrapper */
    .logos-wrapper {
      .confidenceitem {
        img {
          width: 100px;
        }
      }
    }
  }
  /* swiper container */
  .swiper {
    position: relative;
  }
  /* swiper button prev */
  .swiper-button-prev {
    position: absolute;
    top: 160px;
    left: 48%;
    background-color: ${color.oxfordBlue[800]};
    width: 26px;
    height: 26px;
    /* line-height: 26px; */
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    transition: all 450ms ease-in-out;
    @media (max-width: 991.98px) {
      left: 43%;
    }
    &:hover {
      background-color: ${color.primary[350]};
    }
    &:after {
      font-size: 10px;
      color: ${color.common.white};
      font-weight: 700;
    }
  }
  .swiper-button-next {
    position: absolute;
    top: 160px;
    left: 51%;
    background-color: ${color.oxfordBlue[800]};
    width: 26px;
    height: 26px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    transition: all 450ms ease-in-out;
    @media (max-width: 991.98px) {
      left: 53%;
    }
    &:hover {
      background-color: ${color.primary[350]};
    }
    &:after {
      font-size: 10px;
      color: ${color.common.white};
      font-weight: 700;
    }
  }

  &.new {
    &.bg-lightgradient {
      .overlay {
        display: none;
      }
    }
    &.bg-sprite {
      .eyebrow {
        border: 2px solid ${color.oxfordBlue[600]} !important;
        color: ${color.oxfordBlue[600]} !important;
      }
      .description {
        color: ${color.oxfordBlue[900]} !important;
      }
      .heading {
        color: ${color.common.white} !important;
      }
      .overlay {
        &::before {
          background: linear-gradient(
            to right,
            #3dc4d2,
            rgba(255, 255, 255, 0)
          );
        }
        &::after {
          background: linear-gradient(
            to right,
            #7ec658,
            rgba(255, 255, 255, 0)
          );
        }
      }
    }
    &.bg-plum {
      .heading {
        color: ${color.common.white} !important;
      }
      .overlay {
        --gradient-color: ${color.oxfordBlue[900]}, rgba(255, 255, 255, 0) !important;
      }
    }
    .container {
      max-width: 1170px !important;
      @media (max-width: 991.98px) {
        max-width: 770px !important;
      }
      @media (max-width: 767.98px) {
        max-width: 506px !important;
      }
    }
    .heading-container {
      text-align: center;
      max-width: 570px !important;
      margin: 0 auto 64px;
      @media (max-width: 992px) {
        margin: 0 auto 48px;
      }
      @media (max-width: 576px) {
        max-width: 506px;
        margin: 0 auto 40px;
      }
      .eyebrow {
        font-size: 14px;
        font-weight: 500;
        border: 2px solid ${color.secondary[600]};
        border-radius: 40px;
        padding: 7px 15px 3px;
        line-height: 1em;
        display: inline-block;
        margin-bottom: 24px;
        color: ${color.secondary[600]};
        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      h1 {
        font-size: 48px;
        line-height: 60px;
      }
      h2 {
        font-size: 36px;
        line-height: 44px;
      }
      h3.heading,
      .heading {
        margin-bottom: 16px;
        font-weight: 900;
        font-size: 36px;
        line-height: 44px;
        text-align: center;
        letter-spacing: -0.02em;
        color: ${color.oxfordBlue[900]};
        @media (max-width: 767.98px) {
          font-size: 30px;
          line-height: 44px;
        }
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .description {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: ${color.copy[700]};
        margin-bottom: 64px;
        @media (max-width: 767.98px) {
          margin-bottom: 40px;
        }
        @media (max-width: 575.98px) {
          font-size: 16px;
        }
      }
    }
    .main-container {
      /* scroll wrapper -> marquee */
      .confidence-scroll-wrapper {
        overflow: hidden;
        position: relative;
        .marquee-container {
          .overlay {
            @media (max-width: 576px) {
              display: none;
            }
          }
        }
        img {
          height: 68px;
          max-width: unset;
          margin: 0 10px;
        }
        .slick-track {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          position: relative;
          .slick-slide {
            margin-right: 40px;
          }
        }
      }
      /* logos wrapper */
      .logos-wrapper {
        justify-content: ${(props) =>
          props?.gridIconSize ? "space-around" : "center"};
        ${(props) => sizes[props?.gridIconSize]};
        margin-bottom: ${(props) =>
          props?.negativeMarginBottom ? "150px" : "unset"};
        @media (max-width: 1700px) {
          margin-bottom: ${(props) =>
            props?.negativeMarginBottom ? "100px" : "unset"};
        }
        @media (max-width: 992px) {
          margin-bottom: ${(props) =>
            props?.negativeMarginBottom ? "50px" : "unset"};
        }
        @media (max-width: 767.98px) {
          justify-content: center;
        }
        @media (max-width: 576px) {
          margin-bottom: ${(props) =>
            props?.negativeMarginBottom ? "25px" : "unset"};
        }
      }
    }
    /* swiper container */
    .swiper {
      min-height: 200px;
      position: relative;
    }
    /* swiper button prev */
    .swiper-button-prev {
      position: absolute;
      top: 160px;
      left: 48%;
      background-color: ${color.oxfordBlue[800]};
      width: 26px;
      height: 26px;
      /* line-height: 26px; */
      text-align: center;
      border-radius: 50%;
      display: inline-block;
      transition: all 450ms ease-in-out;
      @media (max-width: 991.98px) {
        left: 43%;
      }
      &:hover {
        background-color: ${color.primary[350]};
      }
      &:after {
        font-size: 10px;
        color: ${color.common.white};
        font-weight: 700;
      }
    }
    .swiper-button-next {
      position: absolute;
      top: 160px;
      left: 51%;
      background-color: ${color.oxfordBlue[800]};
      width: 26px;
      height: 26px;
      /* line-height: 26px; */
      text-align: center;
      border-radius: 50%;
      display: inline-block;
      transition: all 450ms ease-in-out;
      @media (max-width: 991.98px) {
        left: 53%;
      }
      &:hover {
        background-color: ${color.primary[350]};
      }
      &:after {
        font-size: 10px;
        color: ${color.common.white};
        font-weight: 700;
      }
    }

    /* swiper wrapper */
    .swiper-wrapper {
      margin-top: 30px;
      img {
        height: 68px;
        max-width: 100%;
        object-position: center;
      }
    }
  }
`
